






















import { Component, Vue } from "vue-property-decorator";
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
} from "@/interfaces";
import { dispatchGetQB, dispatchPostQB } from "@/store/admin/actions";
import _ from "lodash";

@Component
export default class QB extends Vue {
  public valid = false;
  public refreshToken = "";

  public async mounted() {
    const code = _.get(this, "$route.query.code", "");
    if (!code) {
      await dispatchGetQB(this.$store, (data) => {
        console.log(data);
        const url = _.get(data, "data.url", "");
        if (url) {
          location.href = url;
        }
      });
    } else {
      const data = await dispatchPostQB(this.$store, {
        code: code,
      });
      this.refreshToken = _.get(data, "data.data.refresh_token", "");
    }
  }
}
